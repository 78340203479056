<template>
  <div class="grow calendar-container overlay">
    <Preview :summery="summery"   v-click-outside="() => (summery = {})" />
    <div class="headings pl-10 mr-10">
      <div
        v-for="item in weekRange"
        :key="item.idx"
        :title="item.locale(lang).format('dddd')"
        class="center d-col opacity-60 font-12 capital"
        :class="{ today: today == item.format('DD/MMM/YYYY') }"
      >
        <span class="font-16">{{ item | weekday(lang) }}</span>
      </div>
    </div>
    <hr />
    <div class="grid-container pa-10 overflow-hidden" ref="grid">
      <div
        v-for="(item, index) in monthRange"
        :key="index"
        @drop="drop"
        @contextmenu.prevent="context($event, item)"
        @mouseleave="() => (summery = {})"
        @click="switchToEventView"
        @dragover.prevent
        :data-timecode="item.format()"
        class="grid-item pt-5"
        :class="{
          today: item.isBefore(firstDay) || item.isAfter(lastDay),
        }"
      >
        <div
          class="dotEvent"
          @click="addEvent(item)"
          v-if="!item.isBefore(today) && item.isBefore(lastDay)"
        >
          <s-icon color="white" width="15" :title="$t('calendar.new_event')"
            >plus-circle-outline</s-icon
          >
        </div>

        <div
          v-if="item.month() + 1 == item.month(month).format('M')"
          class="opacity-60 font-12 today-oval center mb-5 nopointer"
          :class="{ green: today == item.format('DD/MMM/YYYY') }"
        >
          {{ item | day }}
        </div>
        <div class="h100 w100 overflow-hidden row">
          <Appointment
            v-for="appointment in events"
            :key="appointment.idx"
            :appointment="appointment"
            :updating="updating"
            :item="item"
            view="month"
            class="month"
            @mouseover.native="setSummery(appointment)"
          />
        </div>
      </div>
    </div>
    <transition name="fade">
      <ContextMenu
        v-show="contextMenu.show"
        :data="contextMenu"
        v-click-outside="() => (contextMenu.show = false)"
        @click.native="() => (contextMenu.show = false)"
        @createEvent="addEvent(contextMenu.date)"
        ref="menu"
      />
    </transition>
  </div>
</template>

<script>
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { mapState } from "vuex";
import ContextMenu from "./ContextMenu.vue";
import Appointment from "./Appointment.vue";
import Preview from "./Preview.vue";
dayjs.extend(weekOfYear);

export default {
  components: { ContextMenu, Appointment, Preview },
  data() {
    return {
      week: 0,
      firstDay: null,
      lastDay: null,
      weekRange: [],
      monthRange: [],
      today: null,
      times: [],
      updating: true,
      eventsPerSlot: -2,
      contextMenu: { x: 0, y: 0, show: false, pastEvent: false },
      summery: {},
    };
  },
  methods: {
    setSummery(val) {
      if (!val) val = {};
      let xy = document.getElementById(val.id);
      xy = xy.parentElement.getBoundingClientRect();
      val.y = xy.y - 190;
      val.x = xy.x - 80;
      if (window.innerWidth - xy.x - 400 < 0) {
        val.x = xy.x - 380;
        val.y = xy.y - 180;
      }
      if (val.y < 120) {
        val.y = 80;
        val.x = xy.x + 170;
        if (window.innerWidth - xy.x - 400) val.x = xy.x - 380;
      }
      this.summery = val;
    },
    getWeekRange(week) {
      this.weekRange = [];
      let day = dayjs().week(week).startOf("week").add(1, "day");
      for (let x = 0; x < 7; x++) {
        this.weekRange.push(day.add(x, "day"));
      }
    },
    getMonthRange(month) {
      this.monthRange = [];
      let day = dayjs()
        .year(this.year)
        .month(month)
        .startOf("month")
        .subtract(1, "day")
        .startOf("week")
        .add(1, "day");

      for (let x = 0; x < 42; x++) {
        this.monthRange.push(day.add(x, "day"));
      }
    },
    isBeforeCurrentData(val) {
      return dayjs.unix(val).isBefore(dayjs());
    },
    async drop(ev) {
      ev.preventDefault();
      let item = JSON.parse(ev.dataTransfer.getData("text"));
      if (!ev.target.parentNode.classList.contains("grid-item")) return;
      let day = this.convertToAppTimezone(ev);
      let ob = { id: item.id, date: day };
      await this.$store.dispatch("schedule/getEventDetailsByID", ob);
      await this.$store.dispatch("schedule/updateEvent");
      await this.$store.dispatch("calendar/getCalendarEvents");
    },
    convertToAppTimezone(ev) {
      let gridItem = ev.target.parentNode.dataset.timecode;
      let item = JSON.parse(ev.dataTransfer.getData("text"));
      let day = dayjs(gridItem).tz(this.userTimezone);
      let event = dayjs.unix(item.start).tz(item.timezone);
      event = event.add(event.utcOffset() - day.utcOffset(), "minute");
      day = day.minute(event.get("minute"));
      return event.date(day.get("date")).format();
    },
    formatter(val) {
      return dayjs(val).format("DD/MMM/YYYY");
    },
    evalAppointment(item, appointment) {
      let month = item.month() + 1 == item.month(this.month).format("M");
      let event = dayjs.unix(appointment.start).tz(this.userTimezone);
      let day = dayjs(item).isSame(event, "day", "month", "year");
      let hidden = appointment.hidden !== true;
      return day && hidden && month && !this.updating;
    },
    async edit(val, day) {
      let data = { id: val.id, date: day };
      await this.$store.dispatch("schedule/getEventDetailsByID", data);
      return this.$router.push("/schedule/edit");
    },
    switchToEventView(event) {
      if (!event.target.classList.contains("grid-item")) return;
      this.$store.commit("calendar/calendarView", "event");
      this.$router.push({ params: { view: "event" } });
    },
    addEvent(item) {
      let time = this.round();
      let date = item.hour(time.hour()).minute(time.minute());
      this.$store.commit("schedule/resetState");
      this.$store.commit("schedule/setOriginalDateTime", date.format());
      this.$store.commit("schedule/custom", true);
      this.$router.push("/schedule");
    },
    round() {
      let time = dayjs();
      for (let x = 0; x < 16; x++) {
        let format = time.format("mm");
        if (format % 5 == 0) break;
        time = time.add(1, "minute");
      }
      time = time.add(5, "minute");
      return time;
    },
    async context(e, val) {
      this.contextMenu.show = true;
      this.contextMenu.date = val;
      let day = dayjs(val);
      if (dayjs().isSame(dayjs(val), "day", "year", "month")) {
        this.contextMenu.pastEvent = false;
      } else if (day.isBefore(dayjs()) || day.isAfter(dayjs(this.lastDay))) {
        this.contextMenu.pastEvent = true;
      } else this.contextMenu.pastEvent = false;

      this.$refs.menu.$el.style.top = e.clientY + "px";
      this.$refs.menu.$el.style.left = e.clientX + "px";

      window.addEventListener("click", handler, true);
      function handler(e) {
        window.removeEventListener("click", handler, true);
        if (e.target.classList.contains("item")) return;
        e.stopPropagation();
        e.preventDefault();
      }
    },
    handler(e) {
      e.stopPropagation();
      e.preventDefault();
      window.removeEventListener("click", this.handler, true);
    },
    addCountToEvent() {
      this.updating = true;
      for (let event of this.events) {
        let time = dayjs.unix(event.start).startOf("day").format();
        let el = document.querySelectorAll('[data-timecode="' + time + '"]')[0];
        try {
          el.setAttribute("data-count", this.eventsPerSlot);
        } catch (error) {
          this.updating = false;
        }
      }
      let counter = -1;
      for (let event of this.events) {
        counter += 1;
        let time = dayjs.unix(event.start).startOf("day").format();
        let el = document.querySelectorAll('[data-timecode="' + time + '"]')[0];

        try {
          el.dataset.count = Number(el.dataset.count) + 1;
          if (el.dataset.count > 0) this.events[counter].hidden = true;
        } catch (error) {
          this.updating = false;
        }
      }
      this.updating = false;
    },
  },
  computed: {
    ...mapState("calendar", ["events", "filterLocal"]),
    ...mapState("user", ["lang", "tip", "userTimezone"]),
    month() {
      return this.$store.state.calendar.date.month();
    },
    year() {
      return this.$store.state.calendar.date.year();
    },
    weekDate() {
      return this.$store.state.calendar.date.subtract(1, "day").week();
    },
    date() {
      return this.$store.state.calendar.date;
    },
  },
  watch: {
    date(val) {
      let container = this.$refs.grid;
      for (let el of container.children) {
        el.removeAttribute("data-count");
      }
      this.firstDay = dayjs()
        .year(this.year)
        .month(this.month)
        .startOf("month");
      this.lastDay = dayjs().year(this.year).month(this.month).endOf("month");
      this.getMonthRange(this.month);
    },
    events() {
      this.addCountToEvent();
    },
    filterLocal() {
      this.addCountToEvent();
    },
  },
  filters: {
    hour(val) {
      return val.format("H");
    },
    weekday(val, lang) {
      return val.locale(lang).format("ddd");
    },
    timestamp(val) {
      return val.format("DD/MMM/YYYY-H:mm");
    },
    day(val) {
      return val.format("D");
    },
    month(val) {
      return val.format("MMM");
    },
    start(val, tz) {
      return dayjs.unix(val).tz(tz).format("H:mm");
    },
  },
  beforeUpdate() {
    let container = this.$refs.grid;
    for (let el of container.children) {
      if (Number(el.dataset.count) < 1 || !this.filterLocal)
        el.removeAttribute("data-count");
    }
  },
  mounted() {
    this.week = dayjs().week();
    let month = this.month;
    this.today = dayjs().format("DD/MMM/YYYY");
    this.getWeekRange(this.week);
    this.getMonthRange(month);
    this.firstDay = dayjs().year(this.year).month(month).startOf("month");
    this.lastDay = dayjs().year(this.year).month(month).endOf("month");
    this.$store.dispatch("calendar/getCalendarEvents");
  },
};
</script>

<style scoped lang='less'>
.headings {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 50px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: calc(100% - 71px);
}

.grid-item {
  user-select: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1b2936;
  min-height: 34px;
  transition: all ease 0.2s;
  position: relative;
  border-top: 1px solid #42515e;
  border-left: 1px solid #42515e;
  &:nth-child(7n) {
    border-right: 1px solid #42515e;
  }
  &:nth-child(n + 36) {
    border-bottom: 1px solid #42515e;
  }
}

.grid-item:hover {
  background-color: #141c25;
}

.today:hover {
  background-color: #1b2936;
}

[data-count]:after {
  content: "+" attr(data-count);
  visibility: visible;
  padding: 4px;
  margin: 4px;
  color: rgb(224, 224, 224);
  background-color: #374655;
  border-radius: 50%;
  font-size: 12px;
  width: 15px;
  z-index: 4;
  position: absolute;
  top: 30px;
  left: 0;
}

.today-oval {
  width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 50%;
}

.green {
  background-color: #409875;
}

.weekend {
  background-color: #253e48;
}

.calendar-container {
  height: calc(
    100vh - var(--cal-control-panel) - var(--top-toolbar-height) -
      var(--footer-height)
  );
}

.dotEvent {
  transition: all ease 0.3s;
  position: absolute;
  right: 5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

.grid-item:hover .dotEvent {
  opacity: 1;
  transition: all ease 0.2s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 576px) {
  .hide_on_mobile {
    display: none;
  }

  .grid-container {
    grid-column-gap: 1px;
  }
}
</style>
