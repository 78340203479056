<template>
  <div class="fade" v-bind:class="{ thin: !sideBar }">
    <transition name="expand" mode="out-in">
      <div
        class="wrapper"
        v-bind:class="{ expand: sideBar }"
        v-click-outside:settingsSelector="close"
      >
        <s-calandar @changeDate="changeDate" :prefill="date"></s-calandar>
        <hr class="hr" />
        <div class="selection ma-10 font-14">
          <h4 class="mb-5 pa-10 weight-600">My Calendars</h4>
          <section
            v-if="onpremise"
            @click="toggleGlobal"
            class="align-center pa-10 pointer"
          >
            <s-checkbox v-model="filterGlobal"></s-checkbox>
            <span class="weight-300 mx-10 noselect">Global</span>
          </section>
          <section @click="toggleLocal" class="align-center pa-10 pointer">
            <s-checkbox v-model="filterLocal"></s-checkbox>
            <span class="weight-300 mx-10 noselect">Private</span>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";
export default {
  methods: {
    toggleLocal() {
      this.$store.commit("calendar/filterLocal", !this.filterLocal);
      this.getCalendar("local", !this.filterLocal);
    },
    toggleGlobal() {
      this.$store.commit("calendar/filterGlobal", !this.filterGlobal);
      this.getCalendar("global", !this.filterGlobal);
    },
    getCalendar(value, type) {
    },
    changeDate(val) {
      let currentRoute = this.$router.currentRoute.params.view;
      let con = val.isSame(dayjs(this.date));
      if (con) this.$store.commit("calendar/calendarView", "day");
      if (currentRoute == "day" && con) return;
      if (con) this.$router.push({ params: { view: "day" } });
      else this.$store.commit("calendar/setDate", val);
    },
    close() {
      if (this.$mq != "sm") return;
      this.$store.commit("calendar/sideBar", false);
    },
  },
  computed: {
    ...mapState("calendar", ["sideBar", "date", "filterGlobal", "filterLocal"]),
    ...mapState("user", ["onpremise"]),
  },
};
</script>

<style scoped>
.wrapper {
  white-space: nowrap;
  width: 0px;
  overflow: overlay;
  transition: all 0.25s ease-out;

  transform: translateX(-240px);
  height: calc(
    100vh - var(--cal-control-panel) - var(--top-toolbar-height) -
      var(--footer-height)
  );
}

.expand {
  width: 240px;
  transform: translateX(0px);
}

.fade {
  z-index: 2;
  transition: background-color 0.25s ease-out;
}

@media only screen and (max-width: 576px) {
  .wrapper {
    background-color: #1d2535;
  }

  .expand {
    background-color: #1d2535;
    transition: transform 0.25s ease-out;
    width: 240px;
  }

  .fade {
    width: 100%;
    position: absolute;
    background-color: #0b0e155e;
  }

  .thin {
    width: 0;
    background-color: #ffffff00;
  }
}
</style>