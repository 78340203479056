<template>
  <transition name="dropdown">
    <section v-if="value" class="dropdown" ref="dropdown">
      <button
        @keydown.prevent.shift.tab="up($event)"
        @keydown.prevent.tab.exact="down($event)"
        @keydown.prevent.down="down($event)"
        @keydown.prevent.up="up($event)"
        @click="clickHandle(item.value)"
        :class="{ selected: item.selected }"
        v-for="(item, index) in items"
        :key="index"
      >
        {{ item.display }}
      </button>
    </section>
  </transition>
</template>

<script>
export default {
  props: ["items", "value"],
  methods: {
    blur() {
      this.focusSelectNode();
    },
    close() {
      this.$emit("input", false);
    },
    clickHandle(e) {
      this.$emit("clickEvent", e);
      this.close();
    },
    filter(val) {
      return val.selected == true;
    },
    down(el) {
      el = el.target;
      if (el.nextSibling) return el.nextSibling.focus();
      el.parentElement.firstChild.focus();
    },
    up(el) {
      el = el.target;
      if (el.previousSibling) return el.previousSibling.focus();
      el.parentElement.lastChild.focus();
    },
    focusSelectNode() {
      let selected = this.items.findIndex(this.filter);
      this.$refs.dropdown?.childNodes[selected]?.focus();
    },
  },
  watch: {
    async value() {
      if (!this.value) return;
      await this.$wait();
      this.focusSelectNode();
    },
  },
};
</script>

<style scoped lang='less'>
.dropdown {
  position: absolute;
  top: 25px;
  right: 10px;
  background-color: #243748;
  z-index: 3;
  font-size: 16px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  max-height: 300px;
  overflow: auto;
  overflow: overlay;
  padding: 5px;
  border-radius: 2px;
}

button {
  height: 30px;
  min-width: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  color: rgb(160, 160, 160);
  transition: all 0.3s ease;
  color: rgb(255, 255, 255);
  border-radius: 2px;
  outline: none;
}

.selected {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
}

button:focus {
  background-color: hsla(0, 0%, 100%, 0.1);
}

button:hover {
  background-color: hsla(0, 0%, 100%, 0.06);
}

@media only screen and (max-width: 576px) {
  .dropdown {
    background-color: #1d2535;
    left: 0;
    right: 0;
  }
}
</style>