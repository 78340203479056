<template>
  <div class="overlay grow calendar-container ">
    <Preview :summery="summery"   v-click-outside="() => (summery = {})" />
    <div class="headings pl-10 pr-10 mr-10 capital">
      <div
        v-for="item in weekRange"
        :key="item.idx"
        :title="item.locale(lang).format('dddd, LL')"
        class="center d-col opacity-60 font-12 text-center"
        :class="{ today: today == item.format('DD/MMM/YYYY') }"
      >
        <span>{{ item | month(lang) }} {{ item | day }}</span>
        <span class="font-16">{{ item | weekday(lang) }}</span>
      </div>
    </div>
    <hr />
    <div class="grid-container pa-10 overflow-auto" ref="grid">
      <transition name="fade">
        <ContextMenu
          v-show="contextMenu.show"
          :data="contextMenu"
          v-click-outside="() => (contextMenu.show = false)"
          @click.native="() => (contextMenu.show = false)"
          @createEvent="addEvent(contextMenu.date)"
          ref="menu"
        />
      </transition>

      <section
        class="grid-item"
        v-for="(item, index) in times"
        :key="item.idx"
        @drop.prevent="drop"
        @click="switchToEventView"
        @contextmenu.prevent="context($event, item)"
        @createEvent="addEvent(contextMenu.date)"
        @dragover.prevent
        @mouseleave="() => (summery = {})"
        :data-timecode="item.format()"
        :class="{
          weekend:
            (item.format('ddd') == 'Sat' || item.format('ddd') == 'Sun') &&
            item.format('DD/MMM/YYYY') != today,
          today: item.format('DD/MMM/YYYY') == today,
        }"
      >
        <div
          ref="timeline"
          @dragstart="false"
          v-if="item.isSame(currentTime, 'hour', 'day', 'year')"
          class="time_line noselect"
        >
          <div class="value_line">{{ currentTime.format("HH:mm") }}</div>
        </div>
        <div
          v-if="index < to - from"
          class="opacity-40 font-12 ml-5 noselect time"
        >
          {{ item | hour }}:00
        </div>

        <div
          class="dotEvent"
          @click="addEvent(item)"
          v-if="!item.isBefore(time)"
        >
          <s-icon color="white" width="15" :title="$t('calendar.new_event')"
            >plus-circle-outline</s-icon
          >
        </div>

        <div class="h100 w100 overflow-hidden row">
          <Appointment
            v-for="appointment in events"
            :key="appointment.idx"
            :appointment="appointment"
            :updating="updating"
            :item="item"
            @id="updateID"
            @mouseover.native="setSummery(appointment)"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { gsap } from "gsap";
import { mapState } from "vuex";
dayjs.extend(weekOfYear);
import utc from "dayjs/plugin/utc";
import ContextMenu from "./ContextMenu.vue";
import Appointment from "./Appointment.vue";
import Preview from "./Preview.vue";
dayjs.extend(utc);

export default {
  components: { ContextMenu, Appointment, Preview },
  data() {
    return {
      weekRange: [],
      today: null,
      times: [],
      currentTime: dayjs(),
      inverval: null,
      componentKey: 0,
      id: 0,
      updating: true,
      eventsPerSlot: -2,
      contextMenu: { x: 0, y: 0, show: false, pastEvent: false },
      summery: {},
    };
  },
  methods: {
    setSummery(val) {
      if (!val) val = {};
      let xy = document.getElementById(val.id);
      xy = xy.parentElement.getBoundingClientRect();
      val.y = xy.y - 190;
      val.x = xy.x - 80;
      if (window.innerWidth - xy.x - 400 < 0) {
        val.x = xy.x - 380;
        val.y = xy.y - 180;
      }
      if (val.y < 120) {
        val.y = 80;
        val.x = xy.x + 170;
        if (window.innerWidth - xy.x - 400) val.x = xy.x - 380;
      }
      this.summery = val;
    },
    animateTimeline() {
      let el = this.$refs.timeline;
      if (!el) return;
      if (el.length < 1) return;
      el = el[0];
      el.style.visibility = "visible";
      let currentTime = dayjs().minute() * 1.66;
      let endTime = dayjs().endOf("hour");
      let timeLeft = endTime.diff(dayjs(), "s");
      el.style.top = currentTime + "%";
      gsap.to(el, {
        top: "100%",
        duration: timeLeft,
        ease: "none",
        onComplete: () => {
          el.style.visibility = "hidden";
        },
      });
    },
    updateID(val) {
      this.id = val;
    },
    getWeekRange(week) {
      this.weekRange = [];
      let day = this.date.startOf("isoWeek");
      for (let x = 0; x < 7; x++) {
        this.weekRange.push(day.add(x, "day"));
      }
    },
    getDaysRange(idx) {
      let day = dayjs(this.weekRange[idx]);
      day = day.startOf("day").add(this.from, "hour");
      let times = [];
      for (let x = this.from; x < this.to; x++) {
        let hour = day.set("hour", x);
        times.push(hour);
      }
      return times;
    },
    generateDays() {
      this.times = [];
      for (let x = 0; x < 7; x++) {
        this.times.push(this.getDaysRange(x));
      }
      this.times = this.times.flat();
    },
    async drop(ev) {
      let date = this.convertToAppTimezone(ev);
      if (!ev.target.parentNode.classList.contains("grid-item")) return;
      let ob = { id: this.id, date: date };
      await this.$store.dispatch("schedule/getEventDetailsByID", ob);
      await this.$store.dispatch("schedule/updateEvent");
      await this.$store.dispatch("calendar/getCalendarEvents");
    },
    convertToAppTimezone(ev) {
      let gridItem = ev.target.parentNode.dataset.timecode;
      let item = JSON.parse(ev.dataTransfer.getData("text"));
      let timezone = item.timezone;
      let time = dayjs(gridItem).tz(this.userTimezone);

      let event = dayjs.unix(item.start).tz(item.timezone);
      let userTz = time.tz(this.userTimezone).utcOffset();
      let eventTz = dayjs().tz(timezone).utcOffset();
      time = time.minute(event.get("hour"));
      return time.add(eventTz - userTz, "hour").format();
    },
    formatter(val) {
      return dayjs(val).format("DD/MMM/YYYY-H");
    },
    initTimeline() {
      setTimeout(() => {
        this.currentTime = dayjs();
        this.animateTimeline();
      }, 0);
      this.inverval = setInterval(() => {
        this.currentTime = dayjs();
        this.animateTimeline();
      }, 1000);
    },
    switchToEventView(event) {
      if (!event.target.classList.contains("grid-item")) return;
      this.$store.commit("calendar/calendarView", "event");
      this.$router.push({ params: { view: "event" } });
    },
    addEvent(item) {
      this.$store.commit("schedule/resetState");
      this.$store.commit("schedule/setOriginalDateTime", item.format());
      this.$store.commit("schedule/custom", true);
      this.$router.push("/schedule");
    },
    async context(e, val) {
      this.contextMenu.show = true;
      this.contextMenu.date = val;
      let day = dayjs(val);
      if (day.isBefore(dayjs())) this.contextMenu.pastEvent = true;
      else this.contextMenu.pastEvent = false;
      this.$refs.menu.$el.style.top = e.clientY   + "px";
      this.$refs.menu.$el.style.left = e.clientX  + "px";

      window.addEventListener("click", handler, true);
      function handler(e) {
        window.removeEventListener("click", handler, true);
        if (e.target.classList.contains("item")) return;
        e.stopPropagation();
        e.preventDefault();
      }
    },
    addEventCounter() {
      this.updating = true;
      for (let event of this.events) {
        let time = dayjs
          .unix(event.start)
          .tz(this.userTimezone)
          .startOf("hour")
          .format();
        let el = document.querySelectorAll('[data-timecode="' + time + '"]')[0];

        try {
          el.setAttribute("data-count", this.eventsPerSlot);
        } catch (error) {
          this.updating = false;
        }
      }
      let counter = -1;
      for (let event of this.events) {
        counter += 1;
        let time = dayjs.unix(event.start).tz(this.userTimezone);
        time = time.startOf("hour").format();
        let el = document.querySelectorAll('[data-timecode="' + time + '"]')[0];

        try {
          el.dataset.count = Number(el.dataset.count) + 1;
          if (el.dataset.count > 0) this.events[counter].hidden = true;
        } catch (error) {
          this.updating = false;
        }
      }

      this.updating = false;
    },
  },
  computed: {
    from() {
      return this.$store.state.calendar.timeFrom - 1;
    },
    to() {
      return this.$store.state.calendar.timeTo;
    },
    week() {
      return this.$store.state.calendar.date.subtract(1, "day").week();
    },
    month() {
      return this.$store.state.calendar.date.month();
    },
    year() {
      return this.$store.state.calendar.date.year();
    },
    date() {
      return this.$store.state.calendar.date;
    },
    ...mapState("calendar", ["events", "filterLocal"]),
    ...mapState("user", ["lang", "tip", "userTimezone"]),
  },
  watch: {
    date(val) {
      let container = this.$refs.grid;
      for (let el of container.children) {
        el.removeAttribute("data-count");
      }

      this.getWeekRange(this.week);
      this.generateDays();
    },
    async from(val) {
      let range = this.to - this.from;
      this.$refs.grid.style.gridTemplateRows = "repeat(" + range + ", 1fr)";
      let container = this.$refs.grid;
      for (let el of container.children) {
        el.removeAttribute("data-count");
      }

      this.generateDays();
      await this.$wait();
      this.addEventCounter();
    },
    async to(val) {
      let range = this.to - this.from;
      this.$refs.grid.style.gridTemplateRows = "repeat(" + range + ", 1fr)";

      let container = this.$refs.grid;
      for (let el of container.children) {
        el.removeAttribute("data-count");
      }

      this.generateDays();
      await this.$wait();
      this.addEventCounter();
    },
    events() {
      this.addEventCounter();
    },
    filterLocal() {
      this.addEventCounter();
    },
  },
  filters: {
    hour(val) {
      val = val.add(1, "hour").format("H");
      if (val == 0) val = 24;
      return val;
    },
    weekday(val, lang) {
      return val.locale(lang).format("ddd");
    },
    timestamp(val) {
      return val.format("DD/MMM/YYYY-H:mm");
    },
    day(val) {
      return val.format("D");
    },
    month(val, lang) {
      return val.locale(lang).format("MMM");
    },
  },
  destroyed() {
    clearInterval(this.inverval);
  },
  beforeUpdate() {
    let container = this.$refs.grid;
    for (let el of container.children) {
      if (Number(el.dataset.count) < 1 || !this.filterLocal)
        el.removeAttribute("data-count");
    }
  },
  mounted() {
    this.time = dayjs();
    this.today = dayjs().format("DD/MMM/YYYY");
    let range = this.to - this.from;
    this.$refs.grid.style.gridTemplateRows = "repeat(" + range + ", 1fr)";
    this.getWeekRange(this.week);
    this.generateDays();
    this.$store.dispatch("calendar/getCalendarEvents");
    this.initTimeline();
  },
};
</script>

<style scoped >
.time_line {
  visibility: hidden;
  width: 100%;
  height: 2px;
  position: absolute;
  background-color: var(--red);
  z-index: 1;
  top: 0%;
  pointer-events: none;
}

.value_line {
  position: relative;
  bottom: 17px;
  z-index: -1;
  color: var(--red);
  font-size: 14px;
  width: 30px;
  -webkit-box-shadow: 5px 6px 37px -1px rgba(0, 0, 0, 0.76);
  box-shadow: 5px 6px 37px -1px rgba(0, 0, 0, 0.76);
  border-radius: 50%;
}

.headings {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-column-gap: 11px;
  height: 76px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(24, minmax(0, 1fr));
  grid-auto-flow: column;
  grid-column-gap: 11px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #1b2936;
  border-bottom: 1px solid #42515e;
  min-height: 60px;
  transition: all ease 0.2s;
  position: relative;
  user-select: none;
  overflow: hidden;
}

.grid-item:hover {
  background-color: #141c25;
  border-bottom: 1px solid #ffffff;
}

.today {
  background-color: #374655;
  border-bottom: 1px solid black;
}

.weekend {
  background-color: #253e48;
}

.grid-container {
  height: calc(
    100vh - var(--cal-control-panel) - var(--top-toolbar-height) -
      var(--footer-height) - 97px
  );
}

[data-count]:after {
  content: "+" attr(data-count);
  visibility: visible;
  padding: 4px;
  margin: 4px;
  color: rgb(224, 224, 224);
  background-color: #374655;
  border-radius: 50%;
  font-size: 12px;
  width: 15px;
  z-index: 4;
}

.dotEvent {
  transition: all ease 0.3s;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  border-radius: 50%;
  height: 17px;
  right: 5px;
  top: 5px;
  cursor: pointer;
  opacity: 0;
  z-index: 3;
}

.grid-item:hover .dotEvent {
  opacity: 1;
  transition: all ease 0.6s;
}

.time {
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 576px) {
  .hide_on_mobile {
    display: none;
  }

  .grid-container {
    grid-column-gap: 1px;
  }

  .headings {
    grid-column-gap: 1px;
  }
}
</style>
