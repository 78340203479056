<template>
  <div class="calendar_page d-flex d-col h100 vh100">
    <TopToolbar />
    <div class="d-flex grow">
      <Sidebar />
      <Calendar class="grow" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Calendar from "@/components/Calendar/Calendar.vue";
import Sidebar from '@/components/Toolbars/Sidebar'
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import Footer from "@/components/Toolbars/Footer.vue";


export default {
  name: "Register",
  components: {
    Calendar,
    Sidebar,
    TopToolbar,
    Footer
  },
};
</script>

