<template>
  <div class="events grow pt-20">
    <div v-if="$mq != 'sm'" class="heading pb-20">
      <div class="justify-end grey--text weight-300">
        {{ $t("calendar.events.day") }}
      </div>
      <div class="center grey--text weight-300">
        {{ $t("calendar.events.date") }}
      </div>
      <div class="center grey--text weight-300">
        {{ $t("calendar.events.room") }}
      </div>
      <div class="center grey--text weight-300">
        {{ $t("calendar.events.time") }}
      </div>
      <div class="grey--text weight-300">{{ $t("calendar.events.title") }}</div>
    </div>
    <div v-for="(item, index) in events" :key="index">
      <div v-if="filterLocal" class="list">
        <div
          class="center d-col show_on_mobile mb-20 relative"
          v-if="isSameAsLastMonth(index)"
        >
          <div
            :class="{ today: isToday(item.start) }"
            class="center align-start"
          >
            {{ item.start | day(userTimezone) }}
          </div>

          <div
            class="center font-14 opacity-75 mt-5"
            :class="{ 'green--text': isToday(item.start) }"
          >
            <div>
              {{ item.start | monthWeek(lang, userTimezone) }}
            </div>
          </div>
        </div>

        <div
          @click="openEvent(item)"
          class="item d-flex my-5"
          :title="tip && item.title"
        >
          <div class="align-center justify-end font-20">
            <div
              :class="{ 'today todayDK': isToday(item.start) }"
              class="center align-start"
              v-if="isSameAsLastMonth(index)"
            >
              {{ item.start | day(userTimezone) }}
            </div>
          </div>
          <div
            class="center font-14 opacity-75"
            :class="{ 'green--text': isToday(item.start) }"
          >
            <div v-if="isSameAsLastMonth(index)">
              {{ item.start | monthWeek(lang, userTimezone) }}
            </div>
          </div>
          <div
            class="d-flex font-12 weight-600 h100 w100"
            :class="{ 'opacity-60': isBeforeCurrentData(item.start) }"
            :title="tip && item.room"
            :style="{ backgroundColor: item.color }"
          >
            <div class="d-flex center pa-5 hide_on_mobile">
              <s-icon class="noselect nopointer" color="white"
                >monitor-lock</s-icon
              >
            </div>
            <div class="d-flex d-col ml-5">
              <div class="grow d-flex align-center white_space_pre">
                {{ item.room }}
              </div>
            </div>
          </div>
          <div class="center font-13">
            {{ item.start | time(userTimezone) }} -
            {{ item.end | time(userTimezone) }}
          </div>
          <div class="justify-start align-center font-13 weight-400">
            {{ item.title }}
          </div>
        </div>
        <hr v-if="isNewMonth(index)" class="my-20" />
      </div>
    </div>
    <div
      v-if="events.length == 0 || !filterLocal"
      class="w100 text-center opacity-75"
    >
      {{ $t("calendar.noMeetingNote") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  methods: {
    isNewMonth(index) {
      if (index + 1 >= this.events.length) return true;
      let thisMonth = this.day(this.events[index].start).date();
      let nextMonth = this.day(this.events[index + 1].start).date();
      return thisMonth == nextMonth ? false : true;
    },
    isSameAsLastMonth(index) {
      if (index <= 0) return true;
      let thisMonth = this.day(this.events[index].start).date();
      let nextMonth = this.day(this.events[index - 1].start).date();
      return thisMonth == nextMonth ? false : true;
    },
    isToday(start) {
      let day = dayjs().tz(this.userTimezone);
      let event = this.day(start);
      let same = day.isSame(event, "day", "month", "year");
      return same;
    },
    async openEvent(item) {
      await this.$store.dispatch("schedule/getEventDetailsByID", item);
      return this.$router.push("/schedule/edit");
    },
    isBeforeCurrentData(val) {
      return this.day(val).isBefore(dayjs());
    },
    day(val) {
      return dayjs.unix(val).tz(this.userTimezone);
    },
  },
  computed: {
    ...mapState("calendar", ["date", "events", "filterLocal"]),
    ...mapState("user", ["lang", "tip", "userTimezone"]),
  },
  watch: {
    events() {
      setTimeout(() => {
        let page_container = document.querySelectorAll(".events")[0];
        let condition = this.date.month() !== dayjs().month();
        if (condition) return page_container.scrollTo(0, 0);
        let where = this.$screen.sm ? ".todayDK" : ".today";
        let element = document.querySelectorAll(where)[0];
        if (!element) return;
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 0);
    },
  },
  filters: {
    time(val, tz) {
      return dayjs.unix(val).tz(tz).format("HH:mm");
    },
    day(val, tz) {
      return dayjs.unix(val).tz(tz).date();
    },
    monthWeek(val, lang, tz) {
      return dayjs.unix(val).tz(tz).locale(lang).format("dddd").toUpperCase();
    },
  },
  mounted() {
    this.$store.dispatch("calendar/getCalendarEvents");
  },
};
</script>

<style lang="less" scoped>
.events {
  max-height: calc(100vh - 159px);
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.item {
  position: relative;
  display: grid;
  grid-template-columns:
    minmax(0, 80px)
    minmax(0, 3fr)
    minmax(0, 2fr)
    minmax(0, 3fr)
    minmax(0, 4fr);
}

.heading {
  position: relative;
  display: grid;
  grid-template-columns:
    minmax(0, 80px)
    minmax(0, 3fr)
    minmax(0, 2fr)
    minmax(0, 3fr)
    minmax(0, 4fr);
}

.heading_item {
}

.item div {
  overflow: hidden;
  display: flex;
}

.item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.today {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #409875;
  align-items: center;
  position: absolute;
  margin-right: -10px;
}

.room {
  min-height: 30px;
}

@media only screen and (max-height: 576px) {
  .events {
    max-height: calc(100vh - 116px);
  }
}

@media only screen and (max-width: 576px) {
  .today {
    position: relative;
    margin-right: 0px;
  }

  .item {
    position: relative;
  }

  .events {
    max-height: calc(100vh - 116px);
  }

  .item {
    display: grid;
    grid-template-columns:
      minmax(0, 0)
      minmax(0, 0)
      minmax(0, 3fr)
      minmax(0, 3fr)
      minmax(0, 3fr);
  }
}
</style>