<template>
  <div class="wrapper pa-1 b-box">
    <div
      @click="openEvents(data.pastEvent)"
      @contextmenu.prevent
      class="item"
      :class="{ disabled: data.pastEvent }"
    >
      <s-icon color="white" width="16" class="px-10 nopointer mr-10"
        >calendar-plus</s-icon
      >
      {{ $t("calendar.create_event") }}
    </div>
    <div
      @click="globalMeetings()"
      @contextmenu.prevent
      class="item"
      v-if="onpremise"
    >
      <s-icon color="white" width="16" class="px-10 nopointer mr-10"
        >account-group</s-icon
      >
      <div class="nopointer" v-if="global">Show teams meetings</div>
      <div class="nopointer" v-if="!global">Hide teams meetings</div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";
export default {
  props: ["data"],
  data() {
    return {
      global: true,
    };
  },
  methods: {
    openEvents(item) {
      if (!item) this.$emit("createEvent");
    },
    async globalMeetings() {
      setTimeout(() => {
        this.global = !this.global;
      }, 300);
    },
  },
  computed: {
    ...mapState("user", ["onpremise"]),
  },
  mounted() {
    
  },
};
</script>

<style scoped>
.wrapper {
  min-width: 200px;
  background-color: #141a25;
  position: absolute;
  z-index: 5;
  transform: translate(2px, 2px);
  border-radius: 5px;
}

.item {
  color: rgba(255, 255, 255, 0.9);
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.disabled {
  color: grey;
  cursor: not-allowed;
}
</style>