<template>
  <div
    v-if="evalAppointment(item, appointment)"
    class="appointment shadow"
    draggable="true"
    :ref="appointment.id"
    :id="appointment.id"
    :data-timecode="appointment.start"
    :class="{
      'opacity-60': isBeforeCurrentDate(appointment.start),
      'relative' : true
    }"
    :style="{
      backgroundColor: appointment.color,
      transform: `translateY(${timeToPercent(appointment)}`,
    }"
    @dragstart="drag($event, appointment)"
    @click="edit(appointment)"
  >
    <div class="d-flex opacity-60 font-12 h100">
  
      <div class="d-flex d-col ml-5 grow">
        <div class="grow d-flex align-center white_space_pre">
          {{ appointment.title }}
        </div>
        <div class="grow d-flex align-center white_space_pre ">
          {{ appointment.start | timeFormatter(userTimezone) }} -
          {{ appointment.end | timeFormatter(userTimezone) }}
        </div>
      </div>
      <div class="d-flex center pa-5 hide_on_mobile nopointer">
        <s-icon class="noselect nopointer" color="white">monitor-lock</s-icon>
      </div>
    </div>
  </div>
</template>
   
<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  props: ["appointment", "updating", "item", "view"],
  methods: {
    timeToPercent(time) {
      let month = this.view == "month";
      let ratio = 100 / 60;
      let min = dayjs.unix(time.start).minute();
      if (month) ratio = 0;
      return ratio * min + "%";
    },
    evalAppointment(item, appointment) {
      let month = this.view == "month";
      if (month) return this.evalMonthAppointment(item, appointment);
      if (!month) return this.evalWeekDayAppointment(item, appointment);
    },
    evalMonthAppointment(item, appointment) {
      let month = item.month() + 1 == item.month(this.month).format("M");
      let event = dayjs.unix(appointment.start).tz(this.userTimezone);
      let day = dayjs(item).isSame(event, "day", "month", "year");
      let hidden = appointment.hidden !== true;
      return day && hidden && month && !this.updating && this.filterLocal;
    },
    evalWeekDayAppointment(item, appointment) {
      let userTz = item.tz(this.userTimezone, true);
      let eventTz = dayjs.unix(appointment.start).tz(this.userTimezone);
      let day = userTz.isSame(eventTz, "hour", "day", "year");
      let hidden = appointment.hidden !== true;
      return day && hidden && !this.updating && this.filterLocal;
    },
    isBeforeCurrentDate(val) {
      return dayjs.unix(val).tz(this.userTimezone).isBefore(dayjs());
    },
    async edit(val, day) {
      let data = { id: val.id, date: day };
      await this.$store.dispatch("schedule/getEventDetailsByID", data);
      return this.$router.push("/schedule/edit");
    },
    drag(ev, item) {
      let day = dayjs.unix(ev.target.dataset.timecode);
      day = day.tz(this.userTimezone).isBefore(dayjs());
      if (day) return ev.preventDefault();
      ev.dataTransfer.setData("text", JSON.stringify(item));
      this.$emit("id", ev.target.id);
    },
  },
  computed: {
    ...mapState("calendar", ["filterLocal", "month"]),
    ...mapState("user", ["lang", "tip", "userTimezone"]),
  },
  filters: {
    timeFormatter(val, tz) {
      return dayjs.unix(val).tz(tz).format("H:mm");
    },
  },
};
</script>
   
<style scoped>
.appointment {
  width: calc(100% - 2px);
  height: 30px;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  border: 1px solid var(--mud-blue);
  position: absolute;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2px;
}

.appointment:hover {
  border-bottom: 1px solid black;
}

.relative {
  position: relative;
  top : 0;
}

</style>