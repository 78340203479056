<template>
  <div>
    <Controls />
    <div class="d-flex w100">
      <SidePanel  />
      <MonthView v-if="state == 'month'" />
      <WeekView v-if="state == 'week'" />
      <DayView v-if="state == 'day'" />
      <EventView v-if="state == 'event'" />
    </div>
  </div>
</template>

<script>
import Controls from "@/components/Calendar/Controls";
import MonthView from "@/components/Calendar/MonthView";
import WeekView from "@/components/Calendar/WeekView";
import DayView from "@/components/Calendar/DayView";
import EventView from "./EventView";
import SidePanel from "./SidePanel.vue";
export default {
  components: {
    Controls,
    MonthView,
    WeekView,
    DayView,
    EventView,
    SidePanel,
  },
  computed: {
    state() {
      return this.$store.state.calendar.view;
    },
  },
};
</script>

