<template>
  <div ref="bar" class="bar" v-resize @resize="onResize">
    <div class="d-flex align-center space-between controls px-10 wrap noselect">
      <div class="d-flex align-center my-5">
        <section v-if="true" class="settings relative mr-15 settingsSelector">
          <button @click="toggleSettings" class="center iconFilter">
            <s-icon color="grey">menu</s-icon>
          </button>
        </section>
        <button
          @click="decrement"
          class="pointer iconFilter center decrement"
          :title="tip && $t('calendar.tip.previous')"
        >
          <s-icon color="green">chevron-left-circle-outline</s-icon>
        </button>
        <span
          v-if="state == 'month' || state == 'event'"
          class="date px-5 capital"
        >
          {{ monthName }}
        </span>
        <span v-if="state == 'week'" class="date px-5">
          {{ $t("calendar.week") }} {{ week }}</span
        >
        <span v-if="state == 'day'" class="date px-5 capital">{{ day }}</span>
        <button
          @click="increment"
          class="pointer iconFilter center increment"
          :title="tip && $t('calendar.tip.next')"
        >
          <s-icon color="green">chevron-right-circle-outline</s-icon>
        </button>
        <button
          @click="today"
          class="green--text ml-10 noselect buttonFilter todayButton"
          :title="tip && $t('calendar.tip.today')"
        >
          {{ $t("calendar.today") }}
        </button>
      </div>
      <div class="green--text font-28 weight-300 letter-spacing year ml-10">
        {{ year }}
      </div>

      <div class="d-flex align-center my-5 mobile-100">
        <section class="relative" v-if="state !== 'month' && state !== 'event'">
          <button
            class="center buttonFilter fromSelector"
            :title="tip && $t('calendar.tip.from')"
            @click="toggleFrom"
          >
            <span
              v-if="state !== 'month' && state !== 'event'"
              class="opacity-60 ml-6"
            >
              {{ $t("calendar.from") }}</span
            >
            <div class="relative center pointer">
              <span class="green--text mx-5" v-hotkey="{ esc: closeAll }"
                >{{ from - 1 }}:00</span
              >
            </div>
            <s-icon color="green" class="pointer">chevron-down</s-icon>
          </button>
          <Dropdown
            :items="fromData"
            @clickEvent="changeTimeFrom"
            v-model="showFrom"
            v-click-outside:fromSelector="() => (showFrom = false)"
          ></Dropdown>
        </section>

        <section class="relative" v-if="state !== 'month' && state !== 'event'">
          <button
            class="center buttonFilter toSelector ml-10"
            @click="toggleTo"
            :title="tip && $t('calendar.tip.to')"
          >
            <span class="opacity-60 ml-6">
              {{ $t("calendar.to") }}
            </span>
            <div class="relative center pointer">
              <span class="green--text mx-5" v-hotkey="{ esc: closeAll }"
                >{{ to }}:00</span
              >
            </div>
            <s-icon class="pointer" color="green">chevron-down</s-icon>
          </button>
          <Dropdown
            :items="toData"
            @clickEvent="changeTimeTo"
            v-model="showTo"
            v-click-outside:toSelector="() => (showTo = false)"
          ></Dropdown>
        </section>

        <section class="relative spacer">
          <button
            class="buttonFilter center viewSelector ml-10"
            :title="tip && $t('calendar.tip.view')"
            @click="toggleView"
          >
            <span class="opacity-60 hide_on_mobile mx-5">
              {{ $t("calendar.view") }}
            </span>
            <div class="nonwrap center pointer">
              <s-icon class="show_on_mobile" height="16" color="grey"
                >calendar</s-icon
              >
              <span v-hotkey="{ esc: closeAll }" class="green--text mx-5">
                {{ state | capitalize(this) }}
              </span>
              <s-icon color="green">chevron-down</s-icon>
            </div>
          </button>
          <Dropdown
            :items="settingsData"
            @clickEvent="calendarView"
            v-model="showView"
            v-click-outside:viewSelector="() => (showView = false)"
          ></Dropdown>
        </section>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayOfYear from "dayjs/plugin/dayOfYear";
import { mapState } from "vuex";
import Dropdown from "./Dropdown.vue";
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);
export default {
  components: { Dropdown },
  data() {
    return {
      showView: false,
      indexView: -1,
      showTo: false,
      showFrom: false,
      showSettings: false,
    };
  },
  computed: {
    state() {
      return this.$store.state.calendar.view;
    },
    date() {
      return this.$store.state.calendar.date;
    },
    month() {
      return this.$store.state.calendar.date.month();
    },
    week() {
      let week = this.$store.state.calendar.date;
      return week.subtract(1, "day").subtract(1, "week").week();
    },
    day() {
      return this.$store.state.calendar.date.locale(this.lang).format("MMM D");
    },
    year() {
      if (this.state == "week") {
        let start = this.$store.state.calendar.date;
        start = start.startOf("week").add(1, "day").year();
        let end = this.$store.state.calendar.date;
        end = end.endOf("week").add(1, "day").year();
        if (start === end) return start;
        return start + " / " + end;
      }
      return this.$store.state.calendar.date.year();
    },
    monthName() {
      return dayjs().month(this.month).locale(this.lang).format("MMMM");
    },
    from() {
      let val = this.$store.state.calendar.timeFrom;
      if (val < 1) val = 1;
      return val;
    },
    to() {
      return this.$store.state.calendar.timeTo;
    },
    settingsData() {
      let view = this.$router.currentRoute.params.view;
      let data = [
        { value: "month", display: this.$t("calendar.month") },
        { value: "week", display: this.$t("calendar.week") },
        { value: "day", display: this.$t("calendar.day") },
        { value: "event", display: this.$t("calendar.event") },
      ];
      data.map((el) => {
        if (el.value == view) el.selected = true;
      });
      return data;
    },
    toData() {
      let data = [];
      for (let x = this.from; x <= 24; x++) {
        data.push({ value: x, display: `${x}:00` });
      }
      data.map((el) => {
        if (el.value == this.to) el.selected = true;
      });
      return data;
    },
    fromData() {
      let data = [];
      for (let x = 1; x <= this.to; x++) {
        data.push({ value: x, display: `${x - 1}:00` });
      }
      data.map((el) => {
        if (el.value == this.from) el.selected = true;
      });
      return data;
    },
    ...mapState("user", ["lang", "tip"]),
    ...mapState("calendar", ["sideBar"]),
  },
  methods: {
    hover(val) {
      this.indexView = val;
    },
    toggleView() {
      this.showView = !this.showView;
    },
    closeView() {
      this.showView = false;
    },
    toggleTo() {
      this.showTo = !this.showTo;
    },
    closeTo() {
      this.showTo = false;
    },
    toggleFrom() {
      this.showFrom = !this.showFrom;
    },
    closeFrom() {
      this.showFrom = false;
    },
    toggleSettings() {
      this.showSettings = !this.showSettings;
      this.$store.commit("calendar/sideBar", !this.sideBar);
    },
    closeSettings() {
      this.showSettings = false;
    },
    closeAll() {
      this.showView = false;
      this.showTo = false;
      this.showFrom = false;
    },
    async today() {
      this.$store.commit("calendar/today");
      this.$store.dispatch("calendar/getCalendarEvents");
      await this.$wait();
      document.getElementsByClassName("todayButton")[0].focus();
    },
    async calendarView(view) {
      let currentRoute = this.$router.currentRoute.params.view;
      if (currentRoute == view) return;
      this.$router.push({ params: { view: view } });
      this.$store.commit("calendar/calendarView", view);
      await this.$wait();
      document.getElementsByClassName("viewSelector")[0].focus();
    },
    async changeTimeFrom(time) {
      if (time < 1) time = 1;
      if (time < this.to) this.$store.commit("calendar/changeTimeFrom", time);
      await this.$wait();
      document.getElementsByClassName("fromSelector")[0].focus();
    },
    async changeTimeTo(time) {
      if (this.from < time) this.$store.commit("calendar/changeTimeTo", time);
      await this.$wait();
      document.getElementsByClassName("toSelector")[0].focus();
    },
    async increment() {
      if (this.state == "month") this.$store.commit("calendar/incrementMonth");
      if (this.state == "event") this.$store.commit("calendar/incrementMonth");
      if (this.state == "week") this.$store.commit("calendar/incrementWeek");
      if (this.state == "day") this.$store.commit("calendar/incrementDay");
      document.getElementsByClassName("increment")[0].focus();
    },
    async decrement() {
      if (this.state == "month") this.$store.commit("calendar/decrementMonth");
      if (this.state == "event") this.$store.commit("calendar/decrementMonth");
      if (this.state == "week") this.$store.commit("calendar/decrementWeek");
      if (this.state == "day") this.$store.commit("calendar/decrementDay");
      document.getElementsByClassName("decrement")[0].focus();
    },
    onResize() {
      let bar = getComputedStyle(this.$refs.bar).height;
      let root = document.documentElement;
      root.style.setProperty("--cal-control-panel", bar);
    },
  },
  filters: {
    capitalize(val, vm) {
      return vm.$t("calendar." + val);
    },
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        let date = dayjs(this.$router.currentRoute.params.date, "YYYY-MM-DD");
        let today = dayjs().format("YYYY-MM-DD");
        if (!date.isValid()) {
          this.$store.commit("calendar/today");
          this.$router.push({ params: { date: today } });
        } else {
          this.$store.commit("calendar/setDate", date);
        }

        if (params.view == "month")
          return this.$store.commit("calendar/calendarView", params.view);
        if (params.view == "week")
          return this.$store.commit("calendar/calendarView", params.view);
        if (params.view == "day")
          return this.$store.commit("calendar/calendarView", params.view);
        if (params.view == "event")
          return this.$store.commit("calendar/calendarView", params.view);
        this.$router.push({ params: { view: "week" } });
      },
      deep: true,
      immediate: true,
    },
    date(date) {
      let day = date.format("YYYY-MM-DD");
      if (this.$router.currentRoute.params.date == day) return;
      this.$router.push({ params: { date: day } });
    },
  },
  mounted() {
    this.onResize();
    let val = this.$store.state.calendar.timeFrom;
    if (val < 1) this.$store.commit("calendar/changeTimeFrom", 1);
  },
};
</script>

<style scoped lang='less'>
.controls {
  min-height: 55px;
}

.settings_dropdown {
  left: unset;
  top: 27px;
  right: 0px;
}

.date {
  min-width: 80px;
  display: flex;
  justify-content: center;
  opacity: 0.6;
}

.iconFilter:focus,
.iconFilter:hover {
  background-color: rgba(255, 255, 255, 0.07);
  transform: scale(1.1);
  border-radius: 50%;
  outline: none;
}

.buttonFilter:focus,
.buttonFilter:hover {
  outline: none;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}

@media only screen and (max-width: 576px) {
  .bar {
    background-color: #1d2535;
  }

  .year {
    font-size: 16px;
  }

  .mobile-100 {
    width: 100%;
  }

  .spacer {
    margin-left: auto;
  }
}
</style>
