var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.evalAppointment(_vm.item, _vm.appointment))?_c('div',{ref:_vm.appointment.id,staticClass:"appointment shadow",class:{
    'opacity-60': _vm.isBeforeCurrentDate(_vm.appointment.start),
    'relative' : true
  },style:({
    backgroundColor: _vm.appointment.color,
    transform: `translateY(${_vm.timeToPercent(_vm.appointment)}`,
  }),attrs:{"draggable":"true","id":_vm.appointment.id,"data-timecode":_vm.appointment.start},on:{"dragstart":function($event){return _vm.drag($event, _vm.appointment)},"click":function($event){return _vm.edit(_vm.appointment)}}},[_c('div',{staticClass:"d-flex opacity-60 font-12 h100"},[_c('div',{staticClass:"d-flex d-col ml-5 grow"},[_c('div',{staticClass:"grow d-flex align-center white_space_pre"},[_vm._v(" "+_vm._s(_vm.appointment.title)+" ")]),_c('div',{staticClass:"grow d-flex align-center white_space_pre"},[_vm._v(" "+_vm._s(_vm._f("timeFormatter")(_vm.appointment.start,_vm.userTimezone))+" - "+_vm._s(_vm._f("timeFormatter")(_vm.appointment.end,_vm.userTimezone))+" ")])]),_c('div',{staticClass:"d-flex center pa-5 hide_on_mobile nopointer"},[_c('s-icon',{staticClass:"noselect nopointer",attrs:{"color":"white"}},[_vm._v("monitor-lock")])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }