<template>
  <transition name="dropdown">
    <div
      v-if="Object.keys(summery).length !== 0"
      :style="{ top: summery.y + 'px', left: summery.x + 'px' }"
      class="absolute notify nopointer shadow"
    
    >
      <div class="sticky center">
        <div class="green notifyWrapper">
          <div class="row font-24 weight-600 px-20 pb-10 pt-20">
            <span>{{ summery.title }}</span>
          </div>
          <div class="row font-14 opacity-75 pa-10 px-20 py-10">
            <div class="title weight-600">When:</div>
            <div>
              {{ format(summery.start) }} - {{ formatEnd(summery.end) }}
            </div>
          </div>
          <div class="row pa-10 opacity-75 font-14 px-20 py-10">
            <div class="title weight-600">Room:</div>
            <span> {{ summery.room }}</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
//
import dayjs from "dayjs";
export default {
  props: ["summery"],
  methods: {
    format(val) {
      return dayjs.unix(val).format("dddd, D MMMM, H:mm A");
    },
    formatEnd(val) {
      return dayjs.unix(val).format("H:mm A");
    },
  },
};
</script>

<style scoped>
.notify {
  z-index: 3;
  border-radius: 3px;
}

.title {
  min-width: 50px;
}

.notifyWrapper {
  width: 100%;
  max-width: 500px;
  height: 180px;
  max-height: 200px;
  border-radius: 3px;
}
</style>